/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: Montserrat!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #FAFAFA!important;
}

h1 {
  font-family: MontserratBold;
}

.navbar-light .navbar-toggler {
  border: none;
  color: #000;
}

.card {
  box-shadow: none;
  border: none!important;
}

.navbar-light .navbar-toggler:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rounded-pill.btn.btn-secondary {
  border: none;
}

.lead {
  font-size: 22px;
}

.btn-group {
  justify-content: space-between;
}

/* .btn-group .btn {
  max-width: 185px;
} */

.navbar {
  margin-top: 40px!important;
}

.btn-group .btn.active {
  font-weight: bold;
}

.form-control, .custom-file input {
  background: #F8F8F8!important;
border: 1px solid #9C9C9C!important;
border-radius: 10px!important;
height: 45px!important;
}
.inputfile {
  width: calc(100% - 13px);
  padding-left: 5px;
}
.custom-file-label::after {
  height: inherit!important;
  display: flex!important;
  align-items: center!important;
  background-image:url('./img/upload.svg');
  background-repeat: no-repeat;
  content: ''!important;
  background-color: transparent!important;
  border: none!important;
  top: 10px!important;
  right: 10px!important;
}
.removeuseless input#Zoom\ Address, .removeuseless #Linkedin, .removeuseless #Book\ a\ meeting\ link {
  display: none;
}
.form-group {
  margin-bottom: 2rem!important;
}
ul.navbar-nav {
  display: flex;
  flex-direction: row;
}
ul.navbar-nav li {
  margin-left: 30px
}
ul.navbar-nav li a.active {
  color: #47C2B4;
  text-decoration: underline
}
ul.navbar-nav li a {
  color: rgb(55, 56, 56);
  text-decoration: underline
}
.custom-file-label {
  height: inherit;
  max-width: 100%;
  background: #F8F8F8!important;
  border: 1px solid #9C9C9C!important;
  color: #838383!important;
  border-radius: 10px!important;
  height: 45px!important;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.newmessagebar {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #47C2B4;
}

::placeholder {
  color: #838383;
}

.collapse .card-body {
  padding-top: 0!important;
}
.card {
  background-color: transparent!important;
}

.emailcard .card-body {
  overflow: auto!important;

}

.customized_container .col-md-12 img {
  width: 100%;
}

.container .mb-2.col-lg-12 .container {
  padding-left: 0!important;
}

.customized_container .lead {
  padding-right: 100px;
  font-size: 18px;
}


.alertimagewrapper {
  padding-bottom: 30px;
} 
.alertwrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem 0 2rem 0;
}

.alertcontent h5 strong {
  color: rgb(71, 194, 180);
}
.alertcontent ul {
  padding-left: 18px;
}
.alertcontent ul li {
  list-style-type: none;
  position: relative;
}
.alertcontent ul li::before {
  content: '';
  position: absolute;
  top: 10px;
  left: -13px;
  height: 3px;
  width: 3px;
  border-radius: 3px;
  background-color: #212529;
}

.alertcontent {
  padding-left: 35px;
}

.emailcard.card {
  background: #fff!important;
}

h1.display-4 {
  font-size: 48px;
    line-height: 1.1;
    padding-right: 70px;
}

.customized_container {
  padding-bottom: 100px;
}

.suptitle {
  display: block;
  font-weight: 400;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 0rem;
  margin: 0;
  font-size: 22px;
  margin-bottom: 15px;
  color: #4bc3b5 !important;
}
.navbar {
  padding: 1.8em 2% 0 2%!important;
  margin-top: 0!important;
}
.formheading {
  font-family: 'MontserratBold';
font-style: bold;
margin-bottom: 40px;
font-weight: 700;
font-size: 30px;
line-height: 37px;
color: #373838;
}

.btn.generatebtn {
  max-width: 260px;

  font-family: MontserratBold;
  background: #47C2B4;
  border-radius: 60px;
  width: auto;
  padding: 12px 17px;
}
.alert-success {
  text-align: center!important;
}
.guidlink {
  color:#212529;
}
.btn.generatebtn:hover, .btn.generatebtn:focus {
  max-width: 260px;

  font-family: MontserratBold;
  background: #47C2B4;
  box-shadow: inset 0px 3px 16px rgba(0, 0, 0, 0.09), inset 0px 3px 16px rgba(0, 0, 0, 0.09);
  border-radius: 60px;
  width: auto;
  padding: 12px 17px;
}

#message {
  opacity: 0;
  width: 0px;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1300px!important;
  }
  .modal-dialog {
    max-width: 1200px!important;
    margin: 1.75rem auto;
  }

}

@media (max-width: 1200px) {
  h1.display-4 {
    padding-right: 0!important;
  }
  .customized_container .lead {
    padding-right: 0!important;
  }
}


@media (max-width: 992px) {
  ul.navbar-nav li a {
    font-size: 13px;
  }
  .navbar-brand > img {
    width: 120px;
  }
  ul.navbar-nav li {
    margin-left: 9px;
  }
}